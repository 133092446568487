/**
 * @fileoverview Defines a ShippingGroupRelationship item which represents an association between a cart item and a shipping group.
 */

/*global define */
define('viewModels/shipping-group-relationship',['require','knockout','ccConstants','CCi18n','ccStoreConfiguration','ccStoreUtils','ccRestClient','ccDate','viewModels/address'],function (require) {
    "use strict";
    var ko = require('knockout');
    var ccConstants = require('ccConstants');
    var CCi18n = require('CCi18n');
    var CCStoreConfiguration = require('ccStoreConfiguration');
    var StoreUtils = require('ccStoreUtils'); 
    var ccRestClient = require('ccRestClient');
    var CCDate = require('ccDate');
    var Address = require('viewModels/address');

    /**
     * <p>
     *  The view model class that represents an association between a cart item and a shipping group. Strictly
     *  speaking, ShippingGroupRelationships associates a specified quantity of the cart item with a shipping address
     *  and shipping method (not shipping group), however the shipping groups array is directly generated from the 
     *  ShippingGroupRelationships instances (see the createShippingGroups method). When the user selects a shipping 
     *  address and shipping method for a given cart item, it is this class that captures those selections.
     * </p>
     *
     * @private
     * @class
     * @name ShippingGroupRelationship
     * @param {CartItem} cartItem - The associated cart item.
     * @param {Number} quantity - The initial quantity of cart item to be associated to the shipping group.
     * @property {ko.observable<Number>} quantity - The quantity of cart item to be associated to the shipping group.
     * @property {ko.observable} selectedStore - The store selected for picking up the item.
     * @property {ko.observable<Address>} shippingAddress - The address to which this quantity of cart item should be 
     *    shipped.
     * @property {ko.observable<ShippingMethodItemViewModel>} shippingMethod - The method by which this quantity of
     *    cart item should be shipped.
     */
    function ShippingGroupRelationship (cartItem, quantity) {
      var self = this;
      // Default args.
      quantity = quantity || 0;

      // Properties.
      self.catRefId = cartItem.catRefId;
      self.productId = cartItem.productId;
      self.childItems = cartItem.childItems;
      self.assetable = cartItem.assetable;
      self.shippable = cartItem.shippable;
      self.allowVirtualShippingGroup = cartItem.allowVirtualShippingGroup;
      self.isVirtualShippingGroup = cartItem.isVirtualShippingGroup;
      self.quantity = ko.observable(quantity);
      self.updatableQuantity = ko.observable(quantity);
      self.stockStatus = ko.observable(true);
      if(cartItem.availabilityDate && ko.isObservable(cartItem.availabilityDate) ) {
        self.availabilityDate = ko.observable(cartItem.availabilityDate());
      } else if (cartItem.availabilityDate) {
        self.availabilityDate = ko.observable(cartItem.availabilityDate);
      } else {
        self.availabilityDate = ko.observable();
      }
      self.stockState = ko.observable();
      if(cartItem.stockState) {
        if(ko.isObservable(cartItem.stockState) && '' !== cartItem.stockState()) {
    	  self.stockState(cartItem.stockState());
        } else if(!ko.isObservable(cartItem.stockState) && '' !== cartItem.stockState) {
          self.stockState(cartItem.stockState);
        }
      }
      self.orderableQuantityMessage = ko.observable();
      self.shippingOptions = ko.observableArray();
      self.shippingAddress = ko.observable();
      self.shippingMethod = ko.observable();
      self.isPickupInStore = ko.observable();
      self.selectedStore = ko.observable();
      self.shippingGroupId = ko.pureComputed(function() {
        var id = self.productId + self.catRefId + self.quantity();
        if(self.shippingAddress && null != self.shippingAddress()) {
          id = id + self.shippingAddress();
        }
        if(self.shippingMethod && null != self.shippingMethod()) {
          id = id + self.shippingMethod();
        }
        return (self.selectedStore && null != self.selectedStore()) ? id + self.selectedStore().locationId : id;

      }, self).extend({deferred: true});
      self.detailedItemPriceInfo = cartItem.detailedItemPriceInfo;
      
      /*Shopping cart widget UI is directly binded to allItems 
      instead of SGRs. SGR observable array are child properties of line item in allItems observable array.Hence to support 
      fast updates which involves single knockout notification per array update ,we need all the properties 
      of SGR observable array to be observable.Optimized updates can be enabled by marking largeCartConfig.enableOptimizedUpdatesofItems flag 
      true in cc-store-configuration*/
      
      if(ko.isObservable(cartItem.detailedItemPriceInfo)){
        self.detailedItemPriceInfoObservable = ko.observableArray(cartItem.detailedItemPriceInfo());
      }
      else{
        self.detailedItemPriceInfoObservable = ko.observableArray(cartItem.detailedItemPriceInfo)
      }
      if(cartItem.price && ko.isObservable(cartItem.price)) {
        self.price = ko.observable(cartItem.price());
      } else {
        self.price = ko.observable(cartItem.price);
      }
      self.unitPrice = ko.observable(cartItem.unitPrice);
      self.availablePickupDate = ko.observable();
      self.preferredPickupDate = ko.observable();
      if (cartItem.availablePickupDate) {
        self.availablePickupDate = ko.observable(cartItem.availablePickupDate);
      }
      if (cartItem.preferredPickupDate) {
        self.preferredPickupDate = ko.observable(cartItem.preferredPickupDate);
      }
      self.firstName = ko.observable();
      self.middleName = ko.observable();
      self.lastName = ko.observable();
      self.phoneNumber = ko.observable();

      self.updatableQuantity.extend({
        required: {params: true, message: CCi18n.t('ns.common:resources.quantityRequireMsg')},
        digit: {params: true, message: CCi18n.t('ns.common:resources.quantityNumericMsg')},
        trigger: {value: '0', message: CCi18n.t('ns.common:resources.removeItemMsg')}
      });

      self.firstName.extend({
        maxLength: {params: CCStoreConfiguration.ADDRESS_FIRSTNAME_MAXIMUM_LENGTH ? CCStoreConfiguration.ADDRESS_FIRSTNAME_MAXIMUM_LENGTH : ccConstants.CYBERSOURCE_FIRSTNAME_MAXIMUM_LENGTH,
                    message: CCi18n.t('ns.common:resources.maxlengthValidationMsg',{fieldName: CCi18n.t('ns.common:resources.firstNameText'),maxLength:CCStoreConfiguration.ADDRESS_FIRSTNAME_MAXIMUM_LENGTH ? CCStoreConfiguration.ADDRESS_FIRSTNAME_MAXIMUM_LENGTH : ccConstants.CYBERSOURCE_FIRSTNAME_MAXIMUM_LENGTH}) }});

      self.lastName.extend({ maxLength: {params: CCStoreConfiguration.ADDRESS_LASTNAME_MAXIMUM_LENGTH ? CCStoreConfiguration.ADDRESS_LASTNAME_MAXIMUM_LENGTH : ccConstants.CYBERSOURCE_LASTNAME_MAXIMUM_LENGTH,
                    message: CCi18n.t('ns.common:resources.maxlengthValidationMsg',{fieldName: CCi18n.t('ns.common:resources.lastNameText'),maxLength:CCStoreConfiguration.ADDRESS_LASTNAME_MAXIMUM_LENGTH ? CCStoreConfiguration.ADDRESS_LASTNAME_MAXIMUM_LENGTH : ccConstants.CYBERSOURCE_LASTNAME_MAXIMUM_LENGTH}) }});

      // Very basic checking for phone numbers as there are so many different valid patterns
      self.phoneNumber.extend({ pattern: { params: "^[0-9()+ -]+$", message: CCi18n.t('ns.common:resources.phoneNumberInvalid')},
                                maxLength: { params:  ccConstants.CYBERSOURCE_PHONE_NUMBER_MAXIMUM_LENGTH,message: CCi18n.t('ns.common:resources.maxlengthValidationMsg',{fieldName: CCi18n.t('ns.common:resources.phoneNumberText'),maxLength: ccConstants.CYBERSOURCE_PHONE_NUMBER_MAXIMUM_LENGTH}) } });


      /**
       * Add validation functions to updatable quantity field to ensure it lies between the maximum orderable
       * quantity and greater than zero, and there is available stock.
       *
       * @private
       * @function
       * @name #addLimitsValidation
       * @param {object} cartItem associated with shipping group.
       * @param {object} shGrpInvDetails Inve.
       * @param {boolean} isPreOrderBackOrderEnabled tells if preorder / backorder is enabled.
       */
      self.addLimitsValidation = function (cartItem, shGrpInvDetails, isPreOrderBackOrderEnabled, isOrderAmendment, validOrderableQuantity, orderedQuantityMap) {
        var orderLimit = self.quantity();
        var skuInvDetails = null;
        var actualOrderableQuantity;
        for (var inventoryIndex = 0; inventoryIndex < shGrpInvDetails.length; inventoryIndex++) {
          if (self.productId === shGrpInvDetails[inventoryIndex].productId
              && (self.catRefId === shGrpInvDetails[inventoryIndex].catRefId)) {
            skuInvDetails = shGrpInvDetails[inventoryIndex];
          }
        }
        if(null === skuInvDetails) {
          return; // no inventory details found for current product / sku.
        }
        if (cartItem.productData() && cartItem.productData().notForIndividualSale && cartItem.isThisGWPCommerceItemAndValid()) {
          if (skuInvDetails) {
            skuInvDetails.orderLimit = self.quantity();
          }
        }
        var stockStatus = skuInvDetails.stockStatus === 'IN_STOCK'
            || skuInvDetails.stockStatus === 'PREORDERABLE'
            || skuInvDetails.stockStatus === 'BACKORDERABLE';
        self.stockStatus(stockStatus);
        if(skuInvDetails){
          orderLimit = skuInvDetails.orderLimit
          self.availabilityDate(skuInvDetails.availabilityDate);
        }
        self.updatableQuantity.rules.remove(function (item) {return item.rule == "max";});
        self.updatableQuantity.rules.remove(function (item) {return item.rule == "maxItemQuantity";});

        if(isOrderAmendment){
          actualOrderableQuantity = validOrderableQuantity ;
        }else {
          actualOrderableQuantity = skuInvDetails.orderableQuantity;
        }
        if (actualOrderableQuantity !== null
            && !isNaN(actualOrderableQuantity) && actualOrderableQuantity > 0 ) {
          var insufficientStockMsg = CCi18n.t('ns.common:resources.insufficientStockMsg', {
            stockLimit: orderLimit && orderLimit<actualOrderableQuantity ?orderLimit : actualOrderableQuantity
          });

          self.updatableQuantity.extend({
            maxItemQuantity:{params: {orderableQuantity: actualOrderableQuantity,
              totalQuantity : self.getItemQuantityInCart, orderLimit:orderLimit}, message: insufficientStockMsg}
          });
        } else {
          var outOfStockMsg = CCi18n.t('ns.common:resources.outOfStockMsg');

          self.updatableQuantity.extend({
            max: {params: 0, message: outOfStockMsg}
          });
        }

        var partialMsg = "";
        if(ccRestClient.profileType === ccConstants.PROFILE_TYPE_AGENT) {
            partialMsg = self.getOrderableQuantityMessageForAgent(cartItem, skuInvDetails,isPreOrderBackOrderEnabled,isOrderAmendment,orderedQuantityMap)
        }else{        
        	if(isPreOrderBackOrderEnabled && skuInvDetails && null !== skuInvDetails
                    && (skuInvDetails.inStockQuantity > 0 && self.updatableQuantity() > skuInvDetails.inStockQuantity)) {
                  if (skuInvDetails.backOrderableQuantity > 0) {
                    partialMsg = CCi18n.t('ns.common:resources.partialBackOrderMsg', {stockLimit: skuInvDetails.inStockQuantity});
                  }
                  else if(skuInvDetails.preOrderableQuantity > 0) {
                    partialMsg = CCi18n.t('ns.common:resources.partialPreOrderMsg', {stockLimit: skuInvDetails.inStockQuantity});
                  }
        	}
        	if(shGrpInvDetails){
              self.stockState(skuInvDetails.stockStatus);
            }
        }
        self.orderableQuantityMessage(partialMsg);

        //added this to trigger the validations on load of page.
        self.updatableQuantity.isModified(true);
      };

      /*
      *This method calculates the stock status during Agent Create/Edit order scenario
      *and generates messages wrt to back/pre order, full back/pre order and child items.
      *
      * @private
      * @function
      * @name CartItem#getOrderableQuantityMessageForAgent.
      * @pItem {Object} cart data of the specific item.
      * @pStockData {Object} stockData of the item returned form stockStatus call.
      * @isPreOrderBackOrderEnabled {boolean} boolean value if back/Pre order configuration is enabled.
      * @isOrderAmendment {boolean} value to determine if the flow is from edit order(Agent Flow).
      * @orderedQuantityMap {map} map which holds the specific value of backorder , preorder and in_stock quantities when order was plcaed.
      * @quantityMultiplier {integer} holds the quantity of main item which has child item.
      */
      self.getOrderableQuantityMessageForAgent = function(pItem, pStockData, isPreOrderBackOrderEnabled, isOrderAmendment, orderedQuantityMap, quantityMultiplier) {
        var self = this;
        var statusMsg = "";
        var itemStockStatus = "IN_STOCK";


            var inStockQuantity = pStockData.inStockQuantity;
            var backorderQuantity = pStockData.backOrderableQuantity;
            var preorderQuantity = pStockData.preOrderableQuantity;
            var updatableQuantity;
            if(quantityMultiplier){
              updatableQuantity = pItem.quantity * quantityMultiplier;
            }else{
              updatableQuantity = pItem.updatableQuantity();
            }
            var quantity = updatableQuantity < pStockData.orderableQuantity ? updatableQuantity : pStockData.orderableQuantity;

            var key = pItem.productId + ":" + pItem.catRefId;
            if(isOrderAmendment && orderedQuantityMap[key]){
              backorderQuantity = backorderQuantity + orderedQuantityMap[key].backOrderQuantity;
              preorderQuantity = preorderQuantity + orderedQuantityMap[key].preOrderQuantity;
              inStockQuantity = inStockQuantity + orderedQuantityMap[key].inStockQuantity;

              var nonInStockQuantity = quantity - inStockQuantity;
              if(nonInStockQuantity > 0){
                if(nonInStockQuantity - backorderQuantity > 0){
                  statusMsg = CCi18n.t('ns.common:resources.AgentPreorderableText', {stockLimit: CCi18n.t('ns.common:resources.asteriskSymbol')});
                  itemStockStatus = ccConstants.PREORDERABLE;
                }else{
                  statusMsg = CCi18n.t('ns.common:resources.AgentBackorderableText', {stockLimit: CCi18n.t('ns.common:resources.asteriskSymbol')});
                  itemStockStatus = ccConstants.BACKORDERABLE;
                }
              }else{
                itemStockStatus = "IN_STOCK";
              }

            }else{
              if (pStockData.stockStatus && (quantity - inStockQuantity) > 0) {
                if (backorderQuantity > 0) {
                  statusMsg = CCi18n.t('ns.common:resources.AgentBackorderableText', {stockLimit: CCi18n.t('ns.common:resources.asteriskSymbol')});
                  itemStockStatus = ccConstants.BACKORDERABLE;
                } else if (preorderQuantity > 0) {
                  statusMsg = CCi18n.t('ns.common:resources.AgentPreorderableText', {stockLimit: CCi18n.t('ns.common:resources.asteriskSymbol')});
                  itemStockStatus = ccConstants.PREORDERABLE;
                }
              } else {
                if (pStockData.stockStatus && pStockData.stockStatus === "BACKORDERABLE") {
                  statusMsg = CCi18n.t('ns.common:resources.AgentBackorderableText', {stockLimit: CCi18n.t('ns.common:resources.asteriskSymbol')});
                } else if (pStockData.stockStatus && pStockData.stockStatus === "PREORDERABLE") {
                  statusMsg = CCi18n.t('ns.common:resources.AgentPreorderableText', {stockLimit: CCi18n.t('ns.common:resources.asteriskSymbol')});
                }
              }
            }
            self.stockState(itemStockStatus);
            self.availabilityDate(pStockData.availabilityDate ? CCDate.dateTimeFormatter(pStockData.availabilityDate, null, "medium") : '');

        return statusMsg.trim();
      };

      /**
       * Add checks and validation functions to updatable quantity field of a configured
       * item to make sure the quantity is within the values that makes sure that the
       * main item as well as the child items are in stock.
       *
       * @private
       * @function
       * @name #addConfigurableStockValidation
       * @param {Object} stockData the stock related data of all the skus present
       *                 in the cart.
       * @param {boolean} isPreOrderBackOrderEnabled tells if preorder / backorder is enabled.
       */
      self.addConfigurableStockValidation = function (stockData, isPreOrderBackOrderEnabled, isOrderAmendment, validOrderQuantityMap, orderedQuantityMap) {
        var maxOrderableQuantity = 0;
        var orderLimit = 0;
        var stockStatus = false;
        var availabilityDate = null;
        var stockState = null;
        var validOrderableQuantity
        // Main item
        for (var i = 0; i < stockData.length; i++) {
          if (self.productId === stockData[i].productId
              && self.catRefId === stockData[i].catRefId) {

              if(isOrderAmendment  && validOrderQuantityMap[stockData[i].productId + ":" + stockData[i].catRefId]){
                validOrderableQuantity = validOrderQuantityMap[stockData[i].productId + ":" + stockData[i].catRefId];
              }else {
                validOrderableQuantity = stockData[i].orderableQuantity;
              }
            if (stockData[i].stockStatus === 'IN_STOCK'
                || ( isPreOrderBackOrderEnabled && ( stockData[i].stockStatus === 'BACKORDERABLE' || stockData[i].stockStatus === 'PREORDERABLE')) 
                || ( ccRestClient.profileType === ccConstants.PROFILE_TYPE_AGENT && validOrderableQuantity > 0) )  {

              if ( validOrderableQuantity !== null
                  && !isNaN(validOrderableQuantity) && validOrderableQuantity > 0 ) {
                var partialMsg = "";
                stockStatus = true;
                availabilityDate = stockData[i].availabilityDate;
                stockState = stockData[i].stockStatus;
                maxOrderableQuantity = validOrderableQuantity;
                orderLimit = stockData[i].orderLimit;

                if(ccRestClient.profileType === ccConstants.PROFILE_TYPE_AGENT){
                    partialMsg = self.getOrderableQuantityMessageForAgent(self, stockData[i],isPreOrderBackOrderEnabled,isOrderAmendment,orderedQuantityMap)
                }else{
                	if (isPreOrderBackOrderEnabled
                            && stockData[i].inStockQuantity > 0 && self.updatableQuantity() > stockData[i].inStockQuantity) {
                          if (stockData[i].backOrderableQuantity > 0) {
                            partialMsg = CCi18n.t('ns.common:resources.partialBackOrderMsg', {
                              stockLimit: stockData[i].inStockQuantity
                            });
                          } else if (stockData[i].preOrderableQuantity > 0) {
                            partialMsg = CCi18n.t('ns.common:resources.partialPreOrderMsg', {
                              stockLimit: stockData[i].inStockQuantity
                            });
                          }
                     }	
                }
              }
              else {
                stockStatus = false;
              }
            }
            else {
              stockStatus = false;
            }
            break;
          }
        }

        // Now add a check for all the child items and the quantity. If one of the child
        // items can be added less than the maxOrderableQuantity, then update the
        // maxOrderableQuantity with that value.
        if (self.childItems && stockStatus ) {
          for (var i = 0; i < self.childItems.length; i++) {
            var productId = self.childItems[i].productId;
            var catRefId = self.childItems[i].catRefId;
            var quantity = ko.utils.unwrapObservable(self.childItems[i].quantity);
            var validOrderableQuantityOfChild = 0;           


            for (var j = 0; j < stockData.length; j++) {
              if (productId === stockData[j].productId && catRefId === stockData[j].catRefId) {
            	  
            	if(isOrderAmendment && validOrderQuantityMap[productId + ":" + catRefId]){
                   validOrderableQuantityOfChild = validOrderQuantityMap[productId + ":" + catRefId];
                }else{
                    validOrderableQuantityOfChild = stockData[j].orderableQuantity;
                }            	  
                if (stockData[j].stockStatus === 'IN_STOCK'
                    || ( isPreOrderBackOrderEnabled && ( stockData[j].stockStatus === 'BACKORDERABLE' || stockData[j].stockStatus === 'PREORDERABLE')) 
                    || ( ccRestClient.profileType === ccConstants.PROFILE_TYPE_AGENT && validOrderableQuantityOfChild > 0 ) ) {

                  if (validOrderableQuantityOfChild !== null && !isNaN(validOrderableQuantityOfChild) && validOrderableQuantityOfChild > 0) {
                    // Get the current quantity and floor the max orderable
                    // accordingly.
                    var orderableQuantity = Math.floor(validOrderableQuantityOfChild / quantity);
                    maxOrderableQuantity = (maxOrderableQuantity > orderableQuantity) ? orderableQuantity : maxOrderableQuantity;
                    orderLimit = (orderLimit>stockData[j].orderLimit)?stockData[j].orderLimit:orderLimit;
                    stockStatus = true;
                    if(ccRestClient.profileType === ccConstants.PROFILE_TYPE_AGENT){
                      var quantityMultiplier = self.updatableQuantity();
                      partialMsg = self.getOrderableQuantityMessageForAgent(self.childItems[i], stockData[j],isPreOrderBackOrderEnabled,isOrderAmendment,orderedQuantityMap, quantityMultiplier)
                    }
                  } else {
                    stockStatus = false;
                  }
                } else {
                  stockStatus = false;
                }
                break;
              }
            }
            if (!stockStatus) {
              // If one of the product is out of stock, break it out.
              break;
            }
          }
        }

        
        if(ccRestClient.profileType !== ccConstants.PROFILE_TYPE_AGENT){
          self.availabilityDate(availabilityDate);
          self.stockState(stockState);
        }
        self.stockStatus(stockStatus);

        self.orderableQuantityMessage(partialMsg);
        self.updatableQuantity.rules.remove(function (item) {return item.rule == "max";});
        self.updatableQuantity.rules.remove(function (item) {return item.rule == "maxItemQuantity";});

        if ( stockStatus && maxOrderableQuantity > 0) {
          var insufficientStockMsg = CCi18n.t('ns.common:resources.insufficientStockMsg', {
            stockLimit: (maxOrderableQuantity>orderLimit)?orderLimit:maxOrderableQuantity
          });

          self.updatableQuantity.extend({
            maxItemQuantity:{params: {orderableQuantity:maxOrderableQuantity,
              totalQuantity:self.getItemQuantityInCart, orderLimit:orderLimit, childItems:self.childItems}, message: insufficientStockMsg}
          });
        }
        else {
          var outOfStockMsg = CCi18n.t('ns.common:resources.configurableProductOutOfStockMsg');

          self.updatableQuantity.extend({
            max: {params: 0, message: outOfStockMsg}
          });
        }

        self.updatableQuantity.isModified(true);
      };
    }

    /**
     * Updates the properties of shipping group after a price call.
     *
     * @function
     * @name ShippingGroupRelationship#update
     * @param {Object} data is the object from where it needs to be updated
     * @param {boolean} isMerge tells if update should happen while merging items or not.
     */
    ShippingGroupRelationship.prototype.update = function (data, isMerge) {
      var quantityToUpdate = data.quantity();
      if (isMerge) {
        quantityToUpdate += this.quantity();
        this.updatableQuantity(quantityToUpdate);
      }
      this.quantity(quantityToUpdate);
      this.price(data.price());
      this.unitPrice(data.unitPrice());
      this.detailedItemPriceInfo = data.detailedItemPriceInfo;
      if(ko.isObservable(data.detailedItemPriceInfo)){
        this.detailedItemPriceInfoObservable(data.detailedItemPriceInfo());
      }
      else{
        this.detailedItemPriceInfoObservable(data.detailedItemPriceInfo);
      }
      //For non-refresh cases the shipping address is an js object so shippingAddress().isValid()
      //will throw console error. So we are checking whether postal code is observable or not, as
      //postal code is a mandatory property of an address and it is mandatory from ui also.
      if( this.shippingAddress() &&
          this.shippingAddress().postalCode !== null &&
          this.shippingAddress().postalCode !== undefined &&
          !ko.isObservable(this.shippingAddress().postalCode)){
        var shippingAddress = this.createAddress(this.shippingAddress());
        if (shippingAddress && shippingAddress.isValid &&
            shippingAddress.isValid() && data.shippingAddress() &&
            data.shippingAddress().isValid && data.shippingAddress().isValid()) {
              this.shippingAddress().country = data.shippingAddress().country();
              this.shippingAddress().state = data.shippingAddress().state();
        }
      }else if (this.shippingAddress() && this.shippingAddress().isValid &&
          this.shippingAddress().isValid() && data.shippingAddress() &&
          data.shippingAddress().isValid && data.shippingAddress().isValid()) {
        this.shippingAddress().country(data.shippingAddress().country());
        this.shippingAddress().state(data.shippingAddress().state());
      }
    }

    ShippingGroupRelationship.prototype.populateUserDetails = function (user) {
      if (user && user.loggedIn()) {
        if (!this.firstName())
          this.firstName(user.firstName());
        if (!this.lastName())
          this.lastName(user.lastName());
        if (!this.phoneNumber() && user.contactShippingAddress) {
          this.phoneNumber(user.contactShippingAddress.phoneNumber);
        }
      }
    }
    /**
     * generated a hashcode of shipping group relationship.
     *
     * @function
     * @name ShippingGroupRelationship#hashCode
     * @return returns hashcode.
     */
    ShippingGroupRelationship.prototype.hashCode = function (commerceItemId) {
      var i;
      var string = this.productId + this.catRefId;
      if(commerceItemId && null !== commerceItemId && '' !== commerceItemId) {
    	string += commerceItemId;
      }
      if (this.isPickupInStore() && this.selectedStore() && this.selectedStore().store) {
        string += this.selectedStore().store.locationId;
        if (this.availablePickupDate()) {
          string += this.availablePickupDate();
        }
      } else if (this.shippingAddress() && this.shippingMethod()) {
        //For non-refresh cases the shipping address is an js object so shippingAddress().isValid()
        //will throw console error. So we are checking whether postal code is observable or not, as
        //postal code is a mandatory property of an address and it is mandatory from ui also.
        if(this.shippingAddress().postalCode !== null &&
           this.shippingAddress().postalCode !== undefined &&
           !ko.isObservable(this.shippingAddress().postalCode)){
          var shippingAddress = this.createAddress(this.shippingAddress());
          if(shippingAddress.isValid && shippingAddress.isValid()){
            string += shippingAddress.firstName() + shippingAddress.lastName() + shippingAddress.address1()
                   + shippingAddress.city() + shippingAddress.selectedState() + shippingAddress.selectedCountry()
                   + shippingAddress.postalCode();
          }
        }else if(this.shippingAddress().isValid && this.shippingAddress().isValid()) {
          string += this.shippingAddress().firstName() + this.shippingAddress().lastName() + this.shippingAddress().address1()
              + this.shippingAddress().city() + this.shippingAddress().selectedState() + this.shippingAddress().selectedCountry()
              + this.shippingAddress().postalCode();
        }
        string += this.shippingMethod().value || this.shippingMethod().id || this.shippingMethod().repositoryId;
      }

      return StoreUtils.getHashCode(string);
    }

    /**
    * Create an object of Address from javascript object.
    *
    */
    ShippingGroupRelationship.prototype.createAddress = function(data){
      var translateHelper =  {
        translate: function(key, options) {
          return CCi18n.t('ns.common:resources.' + key, options);
        }
      };
      var shippingAddress = new Address('split-shipping-address', '', translateHelper, '', '');
      shippingAddress.copyFrom(data, []);
      return shippingAddress;
    }

    /**
     * <p>
     *   Generate a unique string key for this instance (see ShippingGroupRelationship#asMap method).
     * </p>
     *
     * @private
     * @function
     * @name ShippingGroupRelationship#generateKey
     * @return {String} a unique string key for this instance.
     */
    ShippingGroupRelationship.prototype.generateKey = function () {
      var shippingAddress = this.shippingAddress();
      var shippingMethod = this.shippingMethod();
      var selectedStore = this.selectedStore();
      var key = '';

      if (this.isPickupInStore()) {
        key += selectedStore.locationId;
        if(this.availablePickupDate && null !== this.availablePickupDate
            && ko.isObservable(this.availablePickupDate) && this.availablePickupDate()) {
          key+=this.availablePickupDate();
        }
        return key;
      }
      if (this.allowVirtualShippingGroup) {
    	if(this.isVirtualShippingGroup) {
    	  key += ccConstants.VIRTUAL_SHIPPING_GROUP_TYPE;
    	}
    	else {
    	  key += ccConstants.HARDGOOD_SHIPPING_GROUP_TYPE;
    	}
      }
      if (shippingAddress) {
     // Checking if toJSON() is available in shippingAddress before calling it, to map the address object
     // else use the old way of mapping.
        var addressJson;
        if (shippingAddress.hasOwnProperty('toJSON')) {
          addressJson = shippingAddress.toJSON();
        } else {
          var mapping = {'ignore':['invalidTracker']};
          addressJson = ko.mapping.toJSON(shippingAddress, mapping);
        }
        var email = addressJson.email;
        delete addressJson.email;
        addressJson.email = email; // making sure that we append email at the last
        key += JSON.stringify(addressJson);
      }
      if (shippingMethod) {
    	if(shippingMethod.repositoryId) {
    		key += shippingMethod.repositoryId;
    	}
    	if(shippingMethod.value) {
    		key += shippingMethod.value;
    	}
        
      }

      return key;
    };

    /**
     * <p>
     *   Return a map representation of the ShippingGroupRelationship instance, where a string key (from 
     *   ShippingGroupRelationship#generateKey) maps to this instance. This method is used internally by 
     *   CartViewModel#createShippingGroups in order to map shipping group relationships to shipping groups.
     * </p>
     *
     * @private
     * @function
     * @name ShippingGroupRelationship#asMap
     * @return {Object} The map representaion of this instance.
     */
    ShippingGroupRelationship.prototype.asMap = function (emailAddress) {
      var shippingGroupMap = {};
      var shippingGroupKey = this.generateKey();
      var shippingGroupItemsMap = {};
      if (this.commerceItemId) {
        var shippingGroupItemKey = this.catRefId + "_" + this.commerceItemId;
      } else {
        var shippingGroupItemKey = this.catRefId;
      }

      var shippingAddress;
      // Checking if toJSON() is available in this.shippingAddress() before calling it, to map the address object
      // else use the old way of mapping.
      if(this.shippingAddress()) {
        if (this.shippingAddress().hasOwnProperty('toJSON')) {
          shippingAddress = this.shippingAddress().toJSON();
        } else {
          var mapping = {'ignore':['invalidTracker']};
          shippingAddress = ko.mapping.toJS(this.shippingAddress(), mapping);
        }
      }

      if(emailAddress && shippingAddress && ((!shippingAddress.email) || (shippingAddress.email != emailAddress))){
    	shippingAddress.email = emailAddress;
      }

      var shippingMethod;
      if (this.shippingMethod() && this.shippingMethod().repositoryId) {
        shippingMethod = {value : this.shippingMethod().repositoryId};
      } else if (this.shippingMethod() && this.shippingMethod().value) {
        shippingMethod = {value : this.shippingMethod().value};
      }
      var locationId = this.selectedStore() ? this.selectedStore().locationId : null;

      // Build shipping group items map.
      if (this.commerceItemId) {
        shippingGroupItemsMap[shippingGroupItemKey] = {
          productId: this.productId,
          catRefId: this.catRefId,
          quantity: parseFloat(this.quantity(), 10),
          // Currently childItems are not being supported in Shipping Group > items.
          // Changes to support passing the childItems in the Shipping Group
          childItems: this.childItems,
          commerceItemId: this.commerceItemId
        };
      } else {
        shippingGroupItemsMap[shippingGroupItemKey] = {
          productId: this.productId,
          catRefId: this.catRefId,
          childItems: this.childItems,
          quantity: parseFloat(this.quantity(), 10)
        };
      }

      // Build shipping group map.
      if (this.isPickupInStore()) {
        shippingGroupItemsMap[shippingGroupItemKey].availablePickupDate = this.availablePickupDate();
        shippingGroupItemsMap[shippingGroupItemKey].preferredPickupDate = this.preferredPickupDate();
        var firstName = this.firstName();
        var lastName = this.lastName();
        var phoneNumber = this.phoneNumber();
        shippingGroupMap[shippingGroupKey] = {
          items: shippingGroupItemsMap,
          type: ccConstants.INSTORE_PICKUP,
          locationId: locationId,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber
        };
      }
      //added to support virtual shipping group for virtual items when the clientConfiguration flag to allow virtual shipping group is set
      else if(this.allowVirtualShippingGroup) {
    	  if(this.isVirtualShippingGroup) {
		    shippingGroupMap[shippingGroupKey] = {
			items: shippingGroupItemsMap,
			type: ccConstants.VIRTUAL_SHIPPING_GROUP_TYPE,
			shippingAddress: shippingAddress,
			shippingMethod : {value : ccConstants.VIRTUAL_SHIPPING_GROUP_TYPE}
			};
    	  }
    	  else {
    		  shippingGroupMap[shippingGroupKey] = {
	          items: shippingGroupItemsMap,
	          type: ccConstants.HARDGOOD_SHIPPING_GROUP_TYPE,
	          shippingAddress: shippingAddress,
	          shippingMethod: shippingMethod
	        }; 
    	  }
      } else {
        shippingGroupMap[shippingGroupKey] = {
          items: shippingGroupItemsMap,
          type: ccConstants.HARDGOOD_SHIPPING_GROUP_TYPE,
          shippingAddress: shippingAddress,
          shippingMethod: shippingMethod
        };
      }
      return shippingGroupMap;
    };

    /**
     * <p>
     *   Add a specified number to the existing quantity. There is no removeQuantity method as passing negative numbers 
     *   gives the same result.
     * </p>
     *
     * @private
     * @function
     * @name ShippingGroupRelationship#addQuantity
     * @param {Number} adjustmentAmount - The amount to be added to the quantity (can be negative).
     */
    ShippingGroupRelationship.prototype.addQuantity = function (adjustmentAmount) {
      this.quantity(parseFloat(this.quantity()) + parseFloat(adjustmentAmount));
      this.updatableQuantity(this.quantity());
    };

    return ShippingGroupRelationship;
  });
